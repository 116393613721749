
import { ListItem, ListItemText, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import axiosInstance from '../../../config/axios';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CloseAccountDialog = () => {
    const { t } = useTranslation();
    const [dialogIsOpen, setDialogOpen] = useState(false);
    const navigate = useNavigate();
    const handleDeleteAccount = async () => {
        try {
            await axiosInstance.delete('/customer/account-delete');
            navigate('/', { replace: true });
            localStorage.clear();
            window.location.reload();
        } catch (error) {
            console.log('delete account error', error);
        }
    }

    return (<>
        <ListItem sx={{ padding: '15px', backgroundColor: '#fbe4e4' }} onClick={() => setDialogOpen(true)}>
            <ListItemText sx={{ color: 'red' }} primary={t('Close Account')} />
        </ListItem>
        <Dialog open={dialogIsOpen} onClose={() => setDialogOpen(false)}>
             <DialogTitle>{t('Are you sure you want to delete the account?')}</DialogTitle> 
            <DialogContent>
                <Typography component='small'>{t('You will be logged out, your subscription will be terminated, and all saved data and activities will be deleted. You will not be able to restore it again.')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button sx={{ color: 'inherit' }} onClick={() => setDialogOpen(false)}>{t('Cancel')}</Button>
                <Button sx={{ color: 'red' }} onClick={handleDeleteAccount}>{t('Close My Account')}</Button>
            </DialogActions>
        </Dialog>
    </>

    )
}
    ;

export default CloseAccountDialog;